import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
import { AuthGuard } from '../shared/auth.guard';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
  canActivate?: any[];
  canActivateChild?: any[];
}

const data: IMenuItem[] = [
  {
    icon: 'simple-icon-people',
    label: 'menu.adminUsuarios',
    to: `${adminRoot}/usuarios`,
    roles: [UserRole.Admin],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    subs: [
      {
        icon: 'simple-icon-user-following',
        label: 'menu.listaUsuario',
        to: `${adminRoot}/usuarios/lista`,
      },
    ],
  },
  {
    icon: 'iconsminds-dashboard',
    label: 'menu.quicksight',
    to: `${adminRoot}/QuickSight`,
    roles: [UserRole.Admin, UserRole.QuickSight],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    subs: [
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-panel1',
        to: `${adminRoot}/QuickSight/Panel1`,
        roles: [UserRole.Admin,UserRole.childQuickSightMiTiendita],
        // canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-panel2',
        to: `${adminRoot}/QuickSight/Panel2`,
        roles: [UserRole.Admin,UserRole.childQuickSightInformacionTienda],
        // canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-sodexo',
        to: `${adminRoot}/QuickSight/sodexo`,
        roles: [UserRole.Admin,UserRole.childQuickSightSodexo],
        // canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-yastas',
        to: `${adminRoot}/QuickSight/yastas`,
        roles: [UserRole.Admin,UserRole.childQuickSightYastas],
        // canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-i-opago-ps',
        to: `${adminRoot}/QuickSight/i-opago-ps`,
        roles: [UserRole.Admin,UserRole.childQuickSightiOPagoPS],
        // canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-pepsico',
        to: `${adminRoot}/QuickSight/PepsiCo`,
        roles: [UserRole.Admin,UserRole.childQuickSightPepsiCo],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-estatusTuAliado',
        to: `${adminRoot}/QuickSight/estatusTuAliado`,
        roles: [UserRole.Admin,UserRole.childQuickSightEstatusTuAliado],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-MiTienditaPro',
        to: `${adminRoot}/QuickSight/MiTienditaPro`,
        roles: [UserRole.Admin,UserRole.childQuickSightMiTienditaPro],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-BosqueReal',
        to: `${adminRoot}/QuickSight/BosqueReal`,
        roles: [UserRole.Admin,UserRole.childQuickSightBosqueReal],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-TuAliado',
        to: `${adminRoot}/QuickSight/TuAliado`,
        roles: [UserRole.Admin,UserRole.childQuickSightTuAliado],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.quicksight-SupervisorTuAliadoMTY',
        to: `${adminRoot}/QuickSight/SupervisorTuAliadoMTY`,
        roles: [UserRole.Admin,UserRole.childQuickSightSupervisorTuAliadoMTY],
        canActivateChild: [AuthGuard]
      }
    ],
  },
  {
    icon: 'simple-icon-link',
    label: 'menu.cadena',
    to: `${adminRoot}/cadenas`,
    roles: [UserRole.Admin, UserRole.Cadenas, UserRole.InvitacionCadenas, UserRole.TransferenciasCadenas, UserRole.MovimientosCadenas],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    subs: [
      {
        icon: 'iconsminds-arrow-merge',
        label: 'menu.asignar',
        to: `${adminRoot}/cadenas/asignar`,
        roles: [UserRole.Admin,UserRole.Cadenas],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'iconsminds-add',
        label: 'menu.cadenasInvitar',
        to: `${adminRoot}/cadenas/invitar`,
        roles: [UserRole.Admin,UserRole.InvitacionCadenas],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'menu.cadenasTransferencia',
        to: `${adminRoot}/cadenas/transferencia`,
        roles: [UserRole.Admin,UserRole.TransferenciasCadenas],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'simple-icon-list',
        label: 'menu.cadenasMovimientos',
        to: `${adminRoot}/cadenas/movimientos`,
        roles: [UserRole.Admin,UserRole.MovimientosCadenas],
        canActivateChild: [AuthGuard]
      }
    ],
  },
  {
    icon: 'iconsminds-motorcycle',
    label: 'menu.bikeisi',
    to: `${adminRoot}/bikeisi`,
    roles: [UserRole.Admin, UserRole.Bikeisi],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    subs: [
      {
        icon: 'simple-icon-layers',
        label: 'menu.boletos',
        to: `${adminRoot}/bikeisi/boletos`,
        roles: [UserRole.Admin,UserRole.Bikeisi],
        canActivateChild: [AuthGuard]
      },
      {
        icon: 'iconsminds-cash-register-2',
        label: 'menu.pagos',
        to: `${adminRoot}/bikeisi/pagos`,
        roles: [UserRole.Admin,UserRole.Bikeisi],
        canActivateChild: [AuthGuard]
      }
    ],
  },
  // {
  //   icon: 'iconsminds-air-balloon-1',
  //   label: 'menu.vien',
  //   to: `${adminRoot}/vien`,
  //   roles: [UserRole.Admin],
  //   canActivate: [AuthGuard],
  //   canActivateChild: [AuthGuard],
  //   subs: [
  //     {
  //       icon: 'simple-icon-paper-plane',
  //       label: 'menu.start',
  //       to: `${adminRoot}/vien/start`,
  //       // roles: [UserRole.Admin],
  //     },
  //   ],
  // },

  // {
  //   icon: 'iconsminds-library',
  //   label: 'menu.docs',
  //   to: 'https://vien-docs.coloredstrategies.com/',
  //   newWindow: true,
  // },
];
export default data;
