import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}
  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const currentUser = this.authService.getUserLogged().getValue();

    if (currentUser && currentUser.email != "") {
      if (route.data && route.data.roles) {
        let bndContinue = false;
        for (let ix = 0; ix < currentUser.rol.length; ix++) {
          if(route.data.roles.includes(currentUser.rol[ix])){
            bndContinue = true;
          }
        }
        if (bndContinue) {
        // if (route.data.roles.includes(currentUser.rol)) {
          return true;
        } else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/user/login']);
      return false;
    }
  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser = this.authService.getUserLogged().getValue();

    if (currentUser && currentUser.email != "") {
      if (route.data && route.data.roles) {
        let bndContinue = false;
        for (let ix = 0; ix < currentUser.rol.length; ix++) {
          if(route.data.roles.includes(currentUser.rol[ix])){
            bndContinue = true;
          }
        }
        if (bndContinue) {
          return true;
        } else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/user/login']);
      return false;
    }
  }
}
