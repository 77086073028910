export enum UserRole {
  Admin,
  Nuevo,
  QuickSight,
  Cadenas,
  childQuickSightMiTiendita,
  childQuickSightInformacionTienda,
  childQuickSightSodexo,
  InvitacionCadenas,
  TransferenciasCadenas,
  MovimientosCadenas,
  childQuickSightYastas,
  childQuickSightiOPagoPS,
  Bikeisi,
  childQuickSightPepsiCo,
  childQuickSightEstatusTuAliado,
  childQuickSightMiTienditaPro,
  childQuickSightBosqueReal,
  childQuickSightTuAliado,
  childQuickSightSupervisorTuAliadoMTY
}
