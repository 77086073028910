import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from } from 'rxjs';

import { fromPromise } from 'rxjs/internal/observable/fromPromise';
import { map, tap, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs/Observable';

import { getUserRole } from 'src/app/utils/util';

import { Auth, API } from 'aws-amplify';

import { Cookie } from 'ng2-cookies';

import { UserRole } from './auth.roles';

import { CadenaListEntity } from "../views/app/entities/cadenas/cadenaList.entity";




export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
  codeVerification: string;
}

export interface IPasswordReset {
  email: string;
  code: string;
  newPassword: string;
}

export interface UserLogged {
  email: string;
  dateInit: Date;
  idApps?: number[];
  rol?: UserRole[];
  cadenasInvitacion?: CadenaListEntity[];
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  userLogged: UserLogged = {
    dateInit: new Date(),
    email: ""
  }
  public loggedIn: BehaviorSubject<UserLogged> = new BehaviorSubject(this.userLogged);

  nextToken: string = "";

  constructor(private auth: AngularFireAuth) { }


  //Función para cargar seción persistente
  validateUserLogged(): BehaviorSubject<UserLogged> {
    let validateRol: any = Cookie.get('rol');
    if (this.loggedIn.getValue().email != "") {
      return this.loggedIn;
    } else if (Cookie.get('isLoggend') == "true" && (validateRol == "" || isNaN(validateRol))) {

      let ids: any = Cookie.get('idApps');
      ids = (ids != '') ? JSON.parse(ids) : [];

      let rol: any = Cookie.get('rol');
      rol = (rol != '') ? JSON.parse(rol) : [];

      let cadenasInvitacion: any = Cookie.get('cadenasInvitacion');
      cadenasInvitacion = (cadenasInvitacion != '') ? JSON.parse(cadenasInvitacion) : [];


      let UserLoggedCopkie = this.formatUserLogged(
        Cookie.get('email'),
        rol,
        [ids, cadenasInvitacion]
      )
      this.loggedIn.next(UserLoggedCopkie)
    }
    return this.loggedIn;
  }

  formatUserLogged(emailUser: string, rolUser: number[], attrUser: any[]): UserLogged {
    let userLogged: UserLogged = {
      dateInit: new Date(),
      email: emailUser,
      rol: rolUser,
      idApps: attrUser[0],
      cadenasInvitacion: attrUser[1]
    }
    return userLogged;
  }

  /** get authenticat state */
  isAuthenticated(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          // this.loggedIn.next(true);
          return true;
        }),
        catchError(error => {
          // this.loggedIn.next(false);
          return of(false);
        })
      );
  }

  /** signout */
  signOutAWS() {
    return fromPromise(Auth.signOut())
      .pipe(
        tap(() => {
          Cookie.deleteAll()
          let userLogged: UserLogged = this.formatUserLogged("", [-1], [[], []]);
          this.loggedIn.next(userLogged);
        }
        )
      );
  }

  // tslint:disable-next-line:typedef
  resetPassword(credentials: IPasswordReset) {
    return this.auth
      .confirmPasswordReset(credentials.code, credentials.newPassword)
      .then((data) => {
        return data;
      });
  }

  // tslint:disable-next-line:typedef
  async getUser() {
    const u = await this.auth.currentUser;
    return { ...u, role: getUserRole() };
  }

  async signUpAWS(credentials: ICreateCredentials) {
    let resp = {};
    try {
      const { user } = await Auth.signUp({
        username: credentials.email,
        password: credentials.password,
        attributes: {
          name: credentials.displayName,
        }
      });
      resp = {
        success: true,
        user: user
      };
      return resp;
    } catch (error) {
      console.log('error signing up:', error);
      resp = {
        success: false,
        error: error
      };
      return resp;
    }
  }

  async confirmSignUp(credentials: ICreateCredentials) {
    let resp = {};
    try {
      const { user } = await Auth.confirmSignUp(credentials.email, credentials.codeVerification);
      resp = {
        success: true,
        user: user
      };
      return resp;
    } catch (error) {
      console.log('error confirming sign up', error);
      resp = {
        success: false,
        error: error
      };
      return resp;
    }
  }

  signInAWS(credentials: ISignInCredentials) {
    var promise = new Promise((resolve, reject) => {
      Auth.signIn(credentials.email, credentials.password).then(value => {
        this.getAttributesUserLogged(credentials.email).then(values => {
          resolve();
        });
      }).catch(err => {
        reject(err);
      })
    });
    return promise;
  }

  //Función para obtener sus datos
  getAttributesUserLogged(email: string) {
    var promise = new Promise((resolve: any, reject: any) => {

      //Se debe de consultar atributos y cargar idApps y rol
      Promise.all([Auth.currentAuthenticatedUser(), this.validateUserAdmin(email)]).then(values => {
        //Carga de aplicaciones
        let aplicationString = values[0].attributes["custom:aplicaciones"];
        let userIdApps = (aplicationString != '' && aplicationString != undefined) ? JSON.parse(aplicationString) : [];//[21,28];//37

        //Carga de canfiguraciones de cadenas
        let invitacionCadenasString = values[0].attributes["custom:invitacionCadenas"];
        let userInvitacionCadenas = (invitacionCadenasString != '' && invitacionCadenasString != undefined) ? JSON.parse(invitacionCadenasString) : [];//[21,28];//37

        //Configuración de Roles
        let userRol: UserRole[] = [];
        if (values.length > 1 && values[1] != undefined) {
          //Obtener los roles de hijos
          let panelesQuickSight = values[0].attributes["custom:quicksightPaneles"];
          let arrayAllGroup: any = values[1];
          if (panelesQuickSight != undefined && panelesQuickSight.indexOf(",") >= 0) {
            arrayAllGroup = this.addGroupOfChild(arrayAllGroup, panelesQuickSight.split(","));
          } else if (panelesQuickSight != undefined) {
            arrayAllGroup = this.addGroupOfChild(arrayAllGroup, [panelesQuickSight.trim()]);
          }
          userRol = this.matchGroupCognitoToRol(arrayAllGroup);
        }
        let userLogged: UserLogged = this.formatUserLogged(email, userRol, [userIdApps, userInvitacionCadenas]);
        this.saveUserCookies(email, userRol, [userIdApps, userInvitacionCadenas]);
        this.loggedIn.next(userLogged);
        resolve();
      }).catch(reason => {
        console.log("Error al obtener usuario", reason);
        let userRol = [];
        let userIdApps = [];
        let userInvitacionCadenas = [];
        let userLogged: UserLogged = this.formatUserLogged(email, userRol, [userIdApps, userInvitacionCadenas]);
        this.saveUserCookies(email, userRol, [userIdApps, userInvitacionCadenas]);
        this.loggedIn.next(userLogged);
        resolve();
      });
    });
    return promise;

  }

  addGroupOfChild(arrayAll, child) {
    for (let index = 0; index < child.length; index++) {
      arrayAll.Groups.push({ 'GroupName': child[index].trim() });
    }
    return arrayAll;
  }

  matchGroupCognitoToRol(group) {
    // var promise = new Promise((resolve:any) => {
    var arrayRoles: UserRole[] = [];
    for (let index = 0; index < group.Groups.length; index++) {
      switch (group.Groups[index].GroupName) {
        case 'admin':
          arrayRoles.push(UserRole.Admin);
          break;
        case 'quicksight':
          arrayRoles.push(UserRole.QuickSight);
          break;
        case 'usercadenas':
          arrayRoles.push(UserRole.Cadenas);
          break;
        case 'MiTiendita':
          arrayRoles.push(UserRole.childQuickSightMiTiendita);
          break;
        case 'InformacionTienda':
          arrayRoles.push(UserRole.childQuickSightInformacionTienda);
          break;
        case 'Sodexo':
          arrayRoles.push(UserRole.childQuickSightSodexo);
          break;
        case 'invitacionCadenas':
          arrayRoles.push(UserRole.InvitacionCadenas);
          break;
        case 'transferenciasCadenas':
          arrayRoles.push(UserRole.TransferenciasCadenas);
          break;
        case 'movimientosCadenas':
          arrayRoles.push(UserRole.MovimientosCadenas);
          break;
        case 'Yastas':
          arrayRoles.push(UserRole.childQuickSightYastas);
          break;
        case 'iOPagoPS':
          arrayRoles.push(UserRole.childQuickSightiOPagoPS);
          break;
        case 'Bikeisi':
          arrayRoles.push(UserRole.Bikeisi);
          break;
        case 'PepsiCo':
          arrayRoles.push(UserRole.childQuickSightPepsiCo);
          break;
        case 'EstatusTuAliado':
          arrayRoles.push(UserRole.childQuickSightEstatusTuAliado);
          break;
        case 'MiTienditaPro':
          arrayRoles.push(UserRole.childQuickSightMiTienditaPro);
          break;
        case 'BosqueReal':
          arrayRoles.push(UserRole.childQuickSightBosqueReal);
          break;
        case 'TuAliado':
          arrayRoles.push(UserRole.childQuickSightTuAliado);
          break;
        case 'SupervisorTuAliadoMTY':
          arrayRoles.push(UserRole.childQuickSightSupervisorTuAliadoMTY);
          break;
        default:
          break;
      }
    }
    return arrayRoles;
  }

  validateUserAdmin(user) {
    var promise = new Promise((resolve, reject) => {
      this.getGroupUserAmplify(user).then(group => {
        resolve(group);
      })
        .catch(err => {
          console.log("No tiene permisos de administrador");
          resolve(undefined);
        })
    });
    return promise;
  }


  //Obtener grupo del usuario logueado
  async getGroupUserAmplify(user) {
    let apiName = 'AdminQueries';
    let path = "/listGroupsForUser";
    let myInit = {
      queryStringParameters: {
        "username": user,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
    const { ...rest } = await API.get(apiName, path, myInit);
    return rest;
  }

  saveUserCookies(email: string, rol: number[], attrUser: any[]) {
    Cookie.set('isLoggend', "true");
    Cookie.set('email', email);
    Cookie.set('rol', JSON.stringify(rol));
    Cookie.set('idApps', JSON.stringify(attrUser[0]));
    Cookie.set('cadenasInvitacion', JSON.stringify(attrUser[1]));
  }

  async sendPasswordEmailAWS(email) {
    return Auth.forgotPassword(email);
  }

  sendPasswordEmailCodeAWS(credentials: IPasswordReset) {
    return Auth.forgotPasswordSubmit(credentials.email, credentials.code, credentials.newPassword);
  }

  getUserLogged() {
    return this.validateUserLogged();
  }

  getEmailLogged() {
    return this.loggedIn.getValue().email;
  }

  getIdAppsLogged() {
    return this.loggedIn.getValue().idApps;
  }

  getCadenasByUserLogged() {
    return this.loggedIn.getValue().cadenasInvitacion;
  }

  /**Seccion de administración de usuarios */
  getUserByEmail(user: string): Observable<boolean> {
    return fromPromise(Auth.userAttributes(user))
      .pipe(
        map(result => {
          console.log(true);
          return true;
        }),
        catchError(error => {
          console.log(false);
          return of(false);
        })
      );
  }
}
