// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  buyUrl : 'https://1.envato.market/6NV1b',
  SCARF_ANALYTICS : false,
  adminRoot: '/app',
  apiUrl: 'https://api.coloredstrategies.com',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: false,
  defaultColor: 'light.blueolympic',/*Color Options:'light.blueyale', 'light.blueolympic', 'light.bluenavy', 'light.greenmoss', 'light.greenlime', 'light.yellowgranola', 'light.greysteel', 'light.orangecarrot', 'light.redruby', 'light.purplemonster''dark.blueyale', 'dark.blueolympic', 'dark.bluenavy', 'dark.greenmoss', 'dark.greenlime', 'dark.yellowgranola', 'dark.greysteel', 'dark.orangecarrot', 'dark.redruby', 'dark.purplemonster'*/
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Nuevo,
  SERVICES_URL: "https://devservice.vmtechmex.net",//'/omar-dev',
  SERVICES_BIKEISI_URL: "https://56r5o4odf6.execute-api.us-east-1.amazonaws.com/omar-dev/Bikeisi",//'/omar-dev',
  SERVICES_AMPLIFY_URL: "https://tgsobqai2j.execute-api.us-east-1.amazonaws.com/dev",
  SERVICES_QUICKSIGHT_URL:"https://ttzvr91sx0.execute-api.us-east-1.amazonaws.com/dev",
  firebase: {
    apiKey: "AIzaSyDxsd5VLVbv1tdl5yGGtbD3c4eYn4bXUgE",
    authDomain: "vmdesa-a1cbd.firebaseapp.com",
    databaseURL: "https://vmdesa-a1cbd.firebaseio.com",
    projectId: "vmdesa-a1cbd",
    storageBucket: "vmdesa-a1cbd.appspot.com",
    messagingSenderId: "422342719580",
    appId: "1:422342719580:web:53c5f6607bb59f0c960e99"
  }
};
