import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getThemeColor, setThemeColor } from './app/utils/util';
import Amplify from 'aws-amplify';
import amplify from './aws-exports';
const END_POINT_AMPLIFY = `${environment.SERVICES_AMPLIFY_URL}`;
const END_POINT_QUICKSIGHT = `${environment.SERVICES_QUICKSIGHT_URL}`;

Amplify.configure(amplify);
Amplify.configure({
  aws_cloud_logic_custom: [
    {
        "name": "AdminQueries",
        "endpoint": `${END_POINT_AMPLIFY}`,
        "region": "us-east-1"
    },
    {
        "name": "BackOfficeService",
        "endpoint": "",//"https://56r5o4odf6.execute-api.us-east-1.amazonaws.com",
        "region": "us-east-1"
    },
    {
        "name": "QuickSightService",
        "endpoint": `${END_POINT_QUICKSIGHT}`,
        "region": "us-east-1"
    }
]
});

if (environment.production) {
  enableProdMode();
}

const color =
  environment.isMultiColorActive || environment.isDarkSwitchActive
    ? getThemeColor()
    : environment.defaultColor;

import('./assets/css/sass/themes/vien.' + color + '.scss')
  .then((x) => {
//     setThemeColor(color);
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch(() => {
    setThemeColor(null);
    window.location.reload();
  });
